import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionFiftyTwo(props) {
  const [data, setdata] = useState(props.data)
  console.log('data')
  console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])

  console.log('section 52')
  console.log(data)

  return (


    <>
      <div className="section bg-white partners guarantee" id="Guarantee"
        style={{ background: data?.section_52_color || '' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 pb-0 text-center">
              <h2 className="mb-4 first initial-text-transform">
                {data?.section_52_left_title}
              </h2>
              <h3 className="gold">
                {data?.section_52_left_head1}
                <br />
                <img
                  src={data?.section_52_left_image}
                  //  "https://trustedclinics.com/images/partners/logo-gold.png" 
                  alt={data?.section_52_image1_alt} className='' width='px' /><br />


              </h3>
              {/* <h3 className='my-3 md:my-0 gold'>{data?.section_52_left_head2}</h3> */}
              <h3 className="text-lg sm:text-xl md:text-2xl break-words">
                {data?.section_52_left_head2}
              </h3>
              {<Markup content={data?.section_51_footer} />}
              {/* <h3 className=''>
                <span className='h3-gold-price'>
                  £350,000

                </span>
                <br />
                guaranteed every year
              </h3> */}
              {/* <p className="pt-2 mb-0">Trusted Clinics works with every clinic individually to understand their personal aspirations and growth plans. With years of experience, proven marketing strategies, unparalleled support and an exclusive patient journey, Trusted Clinics is able to offer clinics an industry-first no win, no fee service.</p> */}
            </div>
            <div className="col-lg-6 pb-0 text-center mt-4 mt-lg-0">
              <p className="pt-1 right-text"><b>
                {/* To be eligible for the guarantee, you must: */}
                {data?.section_52_right_title}
              </b></p>
              <div className="row">
                <div className="col-sm-6 col-lg-6 mb-4">
                  <img className="icons eligible-img-fluid" src={data?.section_52_image1}
                    // "https://trustedclinics.com/images/partners/guarantee-icon1.png" 
                    alt={data?.section_52_image2_alt} />
                  <h4 className="initial-text-transform">
                    {/* Book a free demonstration */}
                    {data?.section_52_right_head1}
                  </h4>
                  <a
                    // href="https://outlook.office365.com/owa/calendar/TrustedClinics1@trustedclinics.com/bookings/"
                    href={data?.section_52_right_button_link}
                    target="_blank" className="btn btn-pink btn-rounded btn-sm ml-auto mr-auto mt-1 mb-2 d-table text-white">
                    {data?.section_52_right_button}
                  </a>
                </div>
                <div className="col-sm-6 col-lg-6 mb-4">
                  <img className="icons eligible-img-fluid"
                    src={data?.section_52_image2}
                    // src="https://trustedclinics.com/images/partners/guarantee-icon2.png"
                    alt={data?.section_52_image3_alt} />
                  <p>
                    {/* Pass the qualification and onboarding process */}
                    {data?.section_52_right_head2}
                  </p>
                </div>
                <div className="col-sm-6 col-lg-6 mb-4">
                  <img className="icons eligible-img-fluid"
                    src={data?.section_52_image3}
                    // src="https://trustedclinics.com/images/partners/guarantee-icon3.png" 
                    alt={data?.section_52_image4_alt} />
                  <p>
                    {/* Select a plan that includes the results-guaranteed offering */}
                    {data?.section_52_right_head3}
                  </p>
                </div>
                <div className="col-sm-6 col-lg-6 mb-4">
                  
                  <img className="icons small  star-position ml-4"
                    // src="https://trustedclinics.com/images/partners/guarantee-icon4.png"
                    src={data?.section_52_image4}
                    alt={data?.section_52_image5_alt} width='100px' />
                  <p>
                    {/* Maintain your reputation with Trusted Clinics reviews */}
                    {data?.section_52_right_head4}
                  </p>
                </div>
                <div className="col-sm-6 col-lg-6 mb-4 mb-md-2">
                  <img className="icons eligible-img-fluid"
                    // src="https://trustedclinics.com/images/partners/guarantee-icon5.png"
                    src={data?.section_52_image5}
                    alt={data?.section_52_image6_alt} />
                  <p>
                    {/* Maintain proper use of the Trusted Clinics system */}
                    {data?.section_52_right_head5}
                  </p>
                </div>
                <div className="col-sm-6 col-lg-6 mb-3 mb-md-2">
                  <img className="icons eligible-img-fluid"
                    src={data?.section_52_image6}
                    // src="https://trustedclinics.com/images/partners/guarantee-icon6.png"
                    alt={data?.section_52_image7_alt} />
                  <p>
                    {/* Have availability to service a minimum of 50 enquiries per month */}
                    {data?.section_52_right_head6}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SectionFiftyTwo