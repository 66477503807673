import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionThirteen(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('sectionone')
    console.log(data)
  
  return (
  //   <div className="section cost-benefits-section bg-off-white bg-white">
  //   <div className="container text-center">
  //     <div className="row">
  //       <div className="col-xl-10  col-lg-10 offset-lg-1">
  //         <h1 className="mb-1">{data?.title || "Contact Us"}</h1>
  //         <h2 className="mb-3">{<Markup  content={ data?.subtitle}/> ||  `We love hearing from patients &amp;<br /> offer 
  //           support every step of the way`}</h2>
  //       </div>
  //     </div>
  //     <div className="row centered m-auto cost-benefits-section-icons pb-3 d-flex justify-content-center">
  //       <div className="col-6 col-md-6  pr-2 pl-2 mb-3">
  //         <div className="card card-treatment h-100">
  //           <div className="card-body">
  //             <div className="treatment-icon">
  //               <img className="icon-one" src={data?.image1 || "https://trustedclinics.com/images/pages/contact/world.png"} width={42} height={39} alt="Finance Available" />
  //             </div>
  //             <h4 className="pt-2">{ data?.description1 || "I Want To Make Contact Via The Website."}</h4>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="col-6 col-md-6  pr-2 pl-2 mb-3">
  //         <div className="card card-treatment h-100">
  //           <div className="card-body">
  //             <div className="treatment-icon">
  //               <img src={data?.image2 || "https://trustedclinics.com/images/pages/about/question.png"} className width={44} height={48} alt="In-Clinic Private Payment Plans" />
  //             </div>
  //             <h4>{ data?.description2 ||  "I Want To Email."}</h4>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="col-6 col-md-6  pr-2 pl-2 mb-3">
  //         <div className="card card-treatment h-100">
  //           <div className="card-body">
  //             <div className="treatment-icon">
  //               <img className="icon-four" src={data?.image3 || "https://trustedclinics.com/images/pages/about/icon-pound.png"} width={45} height={49} alt="Spread The Cost Of Your Treatment Over (Up To) 5 Years." />
  //             </div>
  //             <h4>{ data?.description3 ||  "I'd Like To Ask An Expert A Question About Treatment"}</h4>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="col-6 col-md-6  pr-2 pl-2 mb-3">
  //         <div className="card card-treatment h-100">
  //           <div className="card-body">
  //             <div className="treatment-icon">
  //               <img className="icon-four" src={data?.image4 || "https://trustedclinics.com/images/pages/about/clinic.png"} width={45} height={49} alt="Spread The Cost Of Your Treatment Over (Up To) 5 Years." />
  //             </div>
  //             <h4>{data?.description4 ||  "I want to contact a clinic direct."}</h4>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // </div>
  <div className="section cost-benefits-section bg-off-white bg-white" style={{background:data?.section_13_color || ''}}>
    <div className="container text-center">
      <div className="row">
        <div className="col-xl-10  col-lg-10 offset-lg-1">
          <h1 className="mb-1">{data?.thirteen_title}</h1>
          <h2 className="mb-3">{<Markup  content={ data?.thirteen_discription}/>}</h2>
        </div>
      </div>
      <div className="row centered m-auto cost-benefits-section-icons pb-3 d-flex justify-content-center">
      {
        (data?.thirteen_image1 || data?.thirteen_image_detail1) &&
        <div className="col-6 col-md-6  pr-2 pl-2 mb-3">
          <div className="card card-treatment h-100">
            <a href={data?.sec_13_link1}>
            <div className="card-body">
              <div className="treatment-icon">
              {
                (data?.thirteen_image1) &&
                <img className="icon-one" src={data?.thirteen_image1} width={42} height={39}  alt={data?.section_thirteen_image1_alt} />
              }
                
              </div>
              <h4 className="pt-2">{ data?.thirteen_image_detail1}</h4>
            </div>
            </a>
          </div>
        </div>
      }
      {
        (data?.thirteen_image2 || data?.thirteen_image_detail2) &&
        <div className="col-6 col-md-6  pr-2 pl-2 mb-3">
          <div className="card card-treatment h-100">
          <a href={data?.sec_13_link2}>
            <div className="card-body">
            {
              (data?.thirteen_image2) && 
              <div className="treatment-icon">
                <img  className="icon-one" src={data?.thirteen_image2}  width={44} height={48}  alt={data?.section_thirteen_image2_alt} />
              </div>
            }
              
              <h4>{ data?.thirteen_image_detail2}</h4>
            </div>
            </a>
          </div>
        </div>
      }
      
      {
        (data?.thirteen_image3 || data?.thirteen_image_detail3) &&
        <div className="col-6 col-md-6  pr-2 pl-2 mb-3">
          <div className="card card-treatment h-100">
          <a href={data?.sec_13_link3}>
            <div className="card-body">
            {
              (data?.thirteen_image3) &&
              <div className="treatment-icon">
                <img className="icon-four" src={data?.thirteen_image3} width={45} height={49}  alt={data?.section_thirteen_image3_alt} />
              </div>
            }
              
              <h4>{ data?.thirteen_image_detail3}</h4>
            </div>
            </a>
          </div>
        </div>
      }
      
      {
        (data?.thirteen_image4 || data?.thirteen_image_detail4) &&
        <div className="col-6 col-md-6  pr-2 pl-2 mb-3">
          <div className="card card-treatment h-100">
          <a href={data?.sec_13_link4}>
            <div className="card-body">
              <div className="treatment-icon">
                <img className="icon-four" src={data?.thirteen_image4} width={45} height={49}  alt={data?.section_thirteen_image4_alt} />
              </div>
              <h4>{data?.thirteen_image_detail4}</h4>
            </div>
            </a>
          </div>
        </div>
      }
        
      </div>
    </div>
  </div>
  )
}

export default SectionThirteen