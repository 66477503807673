import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionSixteen(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('sectionsixteen')
    console.log(data)
  
  return (

  <div className="section cost-benefits-section bg-off-white" style={{background:data?.section_16_color || ''}}>
  <div className="container text-center">
    <div className="row">
      <div className="col-xl-10  col-lg-10 offset-lg-1">
        <h2 className="mb-3">{data?.section_sixteen_title}</h2>
      </div>
    </div>
    <div className="row centered m-auto cost-benefits-section-icons pb-3 d-flex justify-content-center">
    {
      (data?.section_sixteen_image1 || data?.section_sixteen_description1) &&
      <div className="col-6 col-md-4  pr-2 pl-2 mb-3">
        <div className="card card-treatment h-100">
        <a href={data?.sec_16_link1}>
          <div className="card-body">
          {
            (data?.section_sixteen_image1) &&
            <div className="treatment-icon">
              <img className="icon-one" src={data?.section_sixteen_image1} width={42} height={39} alt={data?.section_sixteen_image1_alt}  />
            </div>
          }
            
            <h4 className="pt-2">{data?.section_sixteen_description1}</h4>
          </div>
          </a>
        </div>
      </div>
    }
    
    {
      (data?.section_sixteen_image2 || data?.section_sixteen_description2) &&
      <div className="col-6 col-md-4  pr-2 pl-2 mb-3">
        <div className="card card-treatment h-100">
        <a href={data?.sec_16_link2}>
          <div className="card-body">
          {
            (data?.section_sixteen_image2) &&
            <div className="treatment-icon">
              <img  className="icon-one"  src={ data?.section_sixteen_image2}  width={44} height={48} alt={data?.section_sixteen_image2_alt}  />
            </div>
          }
            
            <h4  className="pt-2">{data?.section_sixteen_description2}</h4>
        
          </div>
          </a>
        </div>
      </div>
    }
      
    </div>
  </div>
  <div className="container-fluid">
    <div className="row">
    {
      (data?.section_sixteen_side_image) &&
      <div className="col-12 contact-relative contact-relative-contactus">
        <img src={data?.section_sixteen_side_image} alt={data?.section_sixteen_image3_alt}  width height className="contact-absolute contact-absolute-contactus" />
      </div>
    }
      
    </div>
  </div>
</div>
  )
}

export default SectionSixteen