
import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
const SectionFiftyEight = (props) => {
    const [data,setdata]= useState(props.data)
    console.log('data')
   console.log(data)
    useEffect(() => {
      setdata(props.data)
    }, [props.data])
   
      console.log('section 40')
      console.log(data)
  return (
  <>
        <div className='pt-5 pb-4 text-center' style={{background:data?.section_58_color || ' #fbf6fa'}}>
<div className='container'>
      <div className="row">
        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 pb-0 text-center">
          <h2 className="mb-4 initial-text-transform">{data?.section_58_title}</h2>
          <div>
            {<Markup content={data?.section_58_description}/>}
            {/* <p>The platform has been designed to give patients peace of mind that the clinics they are being sent to are performing at the highest quality.</p> */}
            </div>
        </div>
        <div className="col-12">
          <div className="row text-center mt-1 mt-md-3 mb-5 mb-md-4 d-flex justify-content-center">
            <div className="col-md-6 col-lg-4">
              <img className="icons eligible-img-fluid" src={data?.section_58_image1} alt={data?.section_58_image1_alt} />
              <h4 className="initial-text-transform"> {<Markup content={data?.section_58_description1}/>}</h4>
              {<Markup content={data?.section_58_left_head2}/>}
              {/* <p>Trusted Clinics will undertake research on any clinic wishing to onboard. Trusted Clinics will not represent any clinic that has a less than excellent level of patient care.</p> */}
            </div>
            <div className="col-md-6 col-lg-4">
              <img className="icons eligible-img-fluid" src={data?.section_58_image2} alt={data?.section_58_image2_alt} />
              <h4 className="initial-text-transform">
            
              {<Markup content={data?.section_58_head2}/>}
                {/* Availability to service a minimum<br />of 25 enquiries per month */}
              </h4>
              {<Markup content={data?.section_58_description2}/>}
              {/* <p>Trusted Clinics has restricted the number of clinics that it works with to make sure that every clinic under the Trusted Clinics banner reaches its potential. Trusted Clinics can only onboard clinics that can accept a minimum of 25 enquiries per month.</p> */}
            </div>
            {/* <div className="col-md-12 col-lg-4">
              <img src="https://trustedclinics.com/images/partners/glasses.png" className="images" alt={data?.section_58_image3_alt} />
            </div> */}
            <div className="col-md-6 col-lg-4">
              <img className="icons eligible-img-fluid" src={data?.section_58_image3} alt={data?.section_58_image4_alt} />
              <h4 className="initial-text-transform">
             
              {<Markup content={data?.section_58_head3}/>}
                {/* Offer a<br />free consultation */}
              </h4>
              {/* <p>Market research indicates that a free consultation earns significant trust with patients and with so many clinics offering a free consultation Trusted Clinics can only represent clinics that have this offering.</p> */}
              {<Markup content={data?.section_58_description3}/>}
            </div>
          </div>
          <div className="row text-center mb-md-4 d-flex justify-content-center">
            
            <div className="col-md-6 col-lg-4">
              <img className="icons eligible-img-fluid " src={data?.section_58_image4} alt={data?.section_58_image5_alt} />
              <h4 className="initial-text-transform">
                {/* Offer 0%<br />finance */}{<Markup content={data?.section_58_head4}/>}
                </h4>
              {/* <p>Virtually all Trusted Clinics advertising methods lead with finance. Patient finance opens the world of high-end dentistry to the larger pool, therefore, it is essential that all Trusted Clinics practices have this available.</p> */}
              {<Markup content={data?.section_58_description4}/>}
           </div>
            <div className="col-md-6 col-lg-4">
              <img className="icons eligible-img-fluid" src={data?.section_58_image5} alt={data?.section_58_image6_alt} />
              <h4 className="initial-text-transform">
              {<Markup content={data?.section_58_head5}/>}{/* Relevant trading for<br />24 months or over */}
                </h4>
                {<Markup content={data?.section_58_description5}/>}
              {/* <p>Either as the clinic or as a clinician, you must have had relevant practice experience for a minimum of 2 years to be able to be accepted on the Trusted Clinics platform. This is to ensure patient safety and minimise the risk of early partnership terminations.</p> */}
            </div>
            <div className="col-md-6 col-lg-4">
              <img className="icons eligible-img-fluid" src={data?.section_58_image6} alt={data?.section_58_image7_alt} />
              <h4 className="initial-text-transform">
              {<Markup content={data?.section_58_head6}/>}  {/* Prove relevant qualifications<br />for treatments you are requesting enquiries for */}
                </h4>
                {<Markup content={data?.section_58_description6}/>}
              {/* <p>Trusted Clinics will only direct patients to clinics with relevant experience. Trusted Clinics will ask for proof of qualifications for the treatments you wish to offer before forwarding over any enquiries.</p> */}
            </div>
          </div>
          <div className="row text-center mb-3 mb-md-0 d-flex justify-content-center">
            
            <div className="col-md-12 col-lg-8">
              <img src={data?.section_58_banner1} className="images-after" alt={data?.section_58_image8_alt} />
            </div>
          </div>
        </div>
      </div>
</div>
</div>
  </>
  )
}

export default SectionFiftyEight